import "core-js/stable";
import "regenerator-runtime/runtime";
import "core-js/modules/es.object.get-own-property-descriptors.js";

import * as mounts from "./mount-static";
import { mount } from "./index-base";

setTimeout(() => {
  mount(mounts);
}, 200);
