import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { sendContactForm } from "documents-impl";
import InputGroup from "../input-group/InputGroup.jsx";
import TextAreaGroup from "../text-area-group/TextAreaGroup.jsx";
import Response from "../response/Response.jsx";
import { __, urlConfig } from "../../model/mainhelper";

const OfferContactForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [socialReason, setSocialReason] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [cap, setCap] = useState("");
  const [boatWidth, setBoatWidth] = useState("");
  const [model, setModel] = useState("");
  const [pescaggio, setPeschaggio] = useState("");
  const [dockyard, setDockyard] = useState("");
  const [boatLenght, setBoatLenght] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [terms, setTerms] = useState(false);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const messagesRef = useRef(null);

  const setResponse = (title, msg) => {
    setResponseTitle(title);
    setResponseMsg(msg);
    setShowMsg(true);
  };

  const scrollToMessage = () => {
    let element = document.getElementById("response");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleClick = () => setTerms(!terms);

  const formValidation = () => {
    if (!terms) {
      return false;
    }
    if (
      name == "" ||
      surname == "" ||
      city == "" ||
      email == "" ||
      boatLenght == "" ||
      boatWidth == ""
    ) {
      scrollToMessage();
      setResponse(__("responseTitleError"), __("responseMissingFields"));
      return false;
    }
    if (
      /^[a-zA-Z0-9 èàùìòÈÀÒÙÌéáúíóÉÁÚÍÓëäüïöËÄÜÏÖêâûîôÊÂÛÎÔç'-]*$/.test(
        phone
      ) == false
    ) {
      scrollToMessage();
      setResponse(__("responseTitleError"), __("responseIncorrectPhone"));
      return false;
    }
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ) == false
    ) {
      scrollToMessage();
      setResponse(__("responseTitleError"), __("responseIncorrectEmail"));
      return false;
    }
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setShowMsg(false);
    if (!formValidation()) {
      return false;
    }

    let mailBody = `<div><ul>
      <li><b>Dati personali</b></li>
      ${name && `<li><b>Nome: </b>${name}</li>`}
      ${surname && `<li><b>Cognome: </b>${surname}</li>`}
      ${socialReason && `<li><b>Ragione sociale: </b>${socialReason}</li>`}
      ${address && `<li><b>Indirizzo: </b>${address}</li>`}
      ${phone && `<li><b>Telefono: </b>${phone}</li>`}
      ${email && `<li><b>Email: </b>${email}</li>`}
      ${city && `<li><b>Città: </b>${city}</li>`}
      ${cap && `<li><b>CAP: </b>${cap}</li>`}
      <li><b>Dati imbarcazione</b></li>
      ${boatWidth && `<li><b>Larghezza barca: </b>${boatWidth}</li>`}
      ${boatLenght && `<li><b>Lunghezza barca: </b>${boatLenght}</li>`}
      ${dockyard && `<li><b>Cantiere: </b>${dockyard}</li>`}
      ${pescaggio && `<li><b>Peschaggio: </b>${pescaggio}</li>`}
      ${model && `<li><b>Modello: </b>${model}</li>`}
      ${message && `<li><b>Messaggio: </b>${message}</li>`}
      </ul></div>`.replace(/(^[ \t]*\n)/gm, "");

    let request = {
      name: name,
      surname: surname,
      socialReason: socialReason,
      address: address,
      city: city,
      cap: cap,
      peschaggio: pescaggio,
      dockyard: dockyard,
      model: model,
      message: message,
      boatWidth: boatWidth,
      boatLenght: boatLenght,
      phone: phone,
      email: email,
      mailBody: mailBody,
      to: "form2",
      googleAction: "formOffertaMdl",
    };

    sendContactForm(request).then((data) => {
      scrollToMessage();
      switch (data.status.toString()) {
        case "200":
          if (!data.error) {
            setName("");
            setSurname("");
            setPhone("");
            setSocialReason("");
            setDockyard("");
            setPhone("");
            setCity("");
            setBoatLenght("");
            setBoatWidth("");
            setCap("");
            setAddress("");
            setTerms(false);
            setModel("");
            setPeschaggio("");
            setEmail("");
            setMessage("");
            setResponse(__("responseTitleSent"), __("responseSentEmail"));

            // Analytics tracking
            reservationTracking(true);
          } else {
            setResponse(__("responseTitleError"), data.error);
          }
          break;
        case "Error: 400":
          setResponse(__("responseTitleError"), __("responseCheckForm"));
          break;
        case "Error: 401":
          setResponse(__("responseTitleError"), __("responseRecaptcha"));
          break;
        case "Error: 404":
          setResponse(__("responseTitleError"), __("responseError"));
          break;
        case "Error: 500":
          setResponse(__("responseTitleError"), __("responseError"));
          break;
        default:
          setResponse(__("responseTitleError"), __("responseCheckForm"));
          break;
      }
    });
  };

  return (
    <div className="form--contatti">
      <h4 className="form__title">{__("requestInfo")}</h4>
      <span className="form__subtitle">{__("fillForm")} </span>
      <fieldset>
        <legend> {__("personalData")}</legend>

        <InputGroup
          label={__("formName")}
          name="nome"
          inputClass="form-item required"
          type="text"
          required={true}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        ></InputGroup>
        <InputGroup
          label={__("formSurname")}
          name="cognome"
          inputClass="form-item required"
          type="text"
          required={true}
          value={surname}
          onChange={(value) => {
            setSurname(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item"
          name="ragione_sociale"
          label={__("formSocialReason")}
          type="text"
          required={false}
          value={socialReason}
          onChange={(value) => {
            setSocialReason(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item"
          name="indirizzo"
          label={__("formAddress")}
          type="text"
          required={false}
          value={address}
          onChange={(value) => {
            setAddress(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--wide required"
          name="citta"
          label={__("formCity")}
          type="text"
          required={true}
          value={city}
          onChange={(value) => {
            setCity(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--narrow"
          name="cap"
          label={__("formZIP")}
          type="text"
          required={false}
          value={cap}
          onChange={(value) => {
            setCap(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--narrow"
          label={__("formPhone")}
          name="telefono"
          type="text"
          required={false}
          value={phone}
          onChange={(value) => {
            setPhone(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--wide required"
          label={__("formEmail")}
          name="email"
          type="email"
          required={true}
          value={email}
          onChange={(value) => {
            setEmail(value);
          }}
        ></InputGroup>
      </fieldset>

      <fieldset>
        <legend> {__("boatData")}</legend>

        <InputGroup
          inputClass="form-item required"
          label={__("formWidth")}
          name="larghezza"
          type="text"
          required={true}
          value={boatWidth}
          onChange={(value) => {
            setBoatWidth(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item required"
          label={__("formLength")}
          name="lunghezza"
          type="text"
          required={true}
          value={boatLenght}
          onChange={(value) => {
            setBoatLenght(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--narrow"
          label={__("formDraft")}
          name="pescaggio"
          type="text"
          required={false}
          value={pescaggio}
          onChange={(value) => {
            setPeschaggio(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--narrow"
          label={__("formDock")}
          type="text"
          name="cantiere"
          required={false}
          value={dockyard}
          onChange={(value) => {
            setDockyard(value);
          }}
        ></InputGroup>
        <InputGroup
          inputClass="form-item form-item--narrow"
          label={__("formModel")}
          name="modello"
          type="text"
          required={false}
          value={model}
          onChange={(value) => {
            setModel(value);
          }}
        ></InputGroup>
        <div ref={messagesRef}></div>
        <TextAreaGroup
          inputClass="form-item"
          label={__("formMessage")}
          type="text"
          name="messaggio"
          required={false}
          value={message}
          onChange={(value) => {
            setMessage(value);
          }}
        ></TextAreaGroup>
      </fieldset>

      <span className="form__note">{__("formMandatoryField")}</span>
      <div className="form-row">
        <div className="form-item form-privacy">
          <p>{__("formTermsAndConditions")}</p>
          <input
            type="checkbox"
            name="privacy"
            required
            id="privacy"
            onChange={handleClick}
            checked={terms}
          />
          <label htmlFor="privacy">
            {__("formPrivacy")}{" "}
            <a
              className="privacy-link"
              href={urlConfig("privacy")}
              target="_blank"
              rel="noreferrer"
            >
              {__("formPrivacyLink")}
            </a>
            <br></br>
          </label>
          <br></br>
        </div>

        <button
          data-sitekey="6Le2D74aAAAAADZHt7LgUNJZ_BNw_r4UIuheKWxB"
          onClick={(e) => handleSubmit(e)}
          type="submit"
          className="button button--primary button--icon"
          style={
            terms ? {} : { backgroundColor: "#d0d7e1", pointerEvents: "none" }
          }
        >
          <svg
            className="button__icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 10c.725.013 1.769-.913 2.294-1.294C13.61 6.3 14.477 5.65 15.18 5.102L15.31 5l.065-.05.131-.104.206-.162c.182-.14.288-.359.288-.59V3.5c0-.828-.672-1.5-1.5-1.5h-13C.672 2 0 2.672 0 3.5v.594c0 .231.106.447.288.59.956.747 1.271 1.013 5.418 4.022C6.231 9.087 7.275 10.013 8 10zm6.5 4c.828 0 1.5-.672 1.5-1.5V6.11c0-.154-.181-.244-.303-.148-.7.544-1.625 1.238-4.813 3.554-.634.462-1.768 1.497-2.884 1.487-1.11.006-2.222-1.006-2.881-1.487C1.93 7.2 1.003 6.509.303 5.966.178 5.869 0 5.956 0 6.112V12.5c0 .828.672 1.5 1.5 1.5h13z"
              transform="translate(-95 -3531) translate(71 3507) translate(24 24)"
            />
          </svg>
          <span className="button__label"> {__("formSubmitButton")}</span>
        </button>
      </div>
      <Response
        title={responseTitle}
        description={responseMsg}
        showMsg={showMsg}
        onClose={() => {
          setShowMsg(false);
        }}
      ></Response>
      <div className="g-recaptcha"></div>
    </div>
  );
};

OfferContactForm.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  googleAction: PropTypes.string,
};
export default OfferContactForm;
