import "@pnp/sp/search";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";

export const googleProjectKey = "6Le2D74aAAAAADZHt7LgUNJZ_BNw_r4UIuheKWxB";

export const googleApiKey = "AIzaSyBYeukofq36NosAPCyfar8GTOwgzYAdMjk";

export const callGoogle = (projectKey, action) =>
  new Promise((resolve) =>
    window.grecaptcha.ready(() =>
      window.grecaptcha
        .execute(projectKey, { action: action })
        .then((value) => resolve(value))
    )
  );

export const sendContactForm = async (data) =>
  callGoogle(googleProjectKey, data.googleAction).then((token) =>
    fetch("/_layouts/15/greenpointmail/sendmail.ashx", {
      method: "POST",
      credentials: "same-origin",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify({
        TargetName: data.to,
        Subject: "Contatto dal sito web – " + document.title,
        Body: data.mailBody,
        Token: token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        return error;
      })
  );
