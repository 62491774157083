import React, { useCallback, useState } from "react";
import css from "./BlurOverElement.css";
import PropTypes from "prop-types";
import j from "../../model/j";
import { __ } from "../../model/mainhelper";

export function BlurOverElement({ width, height }) {
  const [showWarning, setShowWarning] = useState(false);

  const onBackgroundClick = useCallback(() => {
    setShowWarning(true);
    const timeoutId = setTimeout(() => setShowWarning(false), 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div
      className={css.blurElement}
      style={{ width: `${width}px`, height: `${height}px` }}
      onClick={onBackgroundClick}
    >
      <div className={j(css.warning, showWarning && css.visible)}>
        {__("bookingAcceptAllTerms")}
      </div>
    </div>
  );
}

BlurOverElement.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
