/**
 * Localize a string
 *
 * @param {String} string String to localize
 *
 * @return {String} String localized
 */
export const __ = (string) =>
  window.MarinaDiLoanoTranslation &&
  window.MarinaDiLoanoTranslation[string] &&
  window.MarinaDiLoanoTranslation[string][getCurrentLanguageCode()]
    ? window.MarinaDiLoanoTranslation[string][getCurrentLanguageCode()]
    : string;

export const urlConfig = (string) =>
  "/" + getCurrentLanguageCode() + "/" + string;

/**
 * Return the current language code
 *
 * @returns {string} Laguage code
 */
export const getCurrentLanguageCode = () => {
  switch (true) {
    case window.location.href.indexOf("/it/") > -1:
      return "it";
    case window.location.href.indexOf("/en/") > -1:
      return "en";
    case window.location.href.indexOf("/de/") > -1:
      return "de";
    case window.location.href.indexOf("/fr/") > -1:
      return "fr";
    default:
      return "it";
  }
};
