import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useResizeObserver } from "usehooks-ts";
import { BlurOverElement } from "./BlurOverElement";

/**
 * Hook that covers another element with a blur-over and makes it impossible to interact.
 * @param {string} rootElementSelector  A selector (compatible with `document.querySelector`) where
 *                                      to mount the blur react component.
 * @param {string} coverElementSelector A selector (compatible with `document.querySelector`) which
 *                                      must be covered. Its top-left corner must be aligned with
 *                                      `rootElementSelector`.
 * @param {boolean} doBlur              Whether to blur and disable the underlying element or nots.
 */
export function useBlurOverElement(
  rootElementSelector,
  coverElementSelector,
  doBlur
) {
  const element = document.querySelector(rootElementSelector);
  const cover = document.querySelector(coverElementSelector);

  const size = useResizeObserver({
    // faking the ref element; strictly not correct, but it works
    ref: {
      current: cover,
    },
  });

  useEffect(() => {
    if (doBlur && size.width && size.height) {
      ReactDOM.render(
        <BlurOverElement width={size.width} height={size.height} />,
        element
      );
      return () => ReactDOM.unmountComponentAtNode(element);
    }
  }, [doBlur, size.width, size.height]);
}
