import { createMountMethod } from "./mount-base";
import CustomContactForm from "./components/contact-form-custom/CustomContactForm";
import OfferContactForm from "./components/contact-form-offer/OfferContactForm";
import BookingForm from "./components/booking-form";

export const mountOfferContactForm = createMountMethod(
  OfferContactForm,
  "offer-contact-form-root",
  {}
);

export const mountCustomContactForm = createMountMethod(
  CustomContactForm,
  "custom-contact-form-root",
  {}
);

export const mountBookingForm = createMountMethod(
  BookingForm,
  "custom-booking-form-root",
  {}
);
