import { useLocalStorage } from "usehooks-ts";

/**
 * Produce getter and setters to access the current status of the consent form.
 * The status is saved in local storage, and thus persisted in the browser.
 * @returns An object containing the consent status (`consentData`, `consentContract`,
 *          `consentUnfairTerms`) and their setters (`setConsentData`, `setConsentContract`,
 *          `setConsentUnfairTerms`).
 */
export function useConsents() {
  const [consentData, setConsentData] = useLocalStorage(
    "booking-form-consent-data",
    "false"
  );
  const [consentContract, setConsentContract] = useLocalStorage(
    "booking-form-consent-contract",
    "false"
  );
  const [consentUnfairTerms, setConsentUnfairTerms] = useLocalStorage(
    "booking-form-consent-unfair-terms",
    "false"
  );

  return {
    consentData,
    setConsentData,
    consentContract,
    setConsentContract,
    consentUnfairTerms,
    setConsentUnfairTerms,
  };
}
