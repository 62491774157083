import React from "react";
import PropTypes from "prop-types";

const TextAreaGroup = (props) => {
  return (
    <>
      <div className={props.inputClass}>
        <label htmlFor={props.name}>{props.label}</label>
        <textarea
          required={props.required}
          name={props.name}
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
        ></textarea>
      </div>
    </>
  );
};

TextAreaGroup.propTypes = {
  label: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  inputClass: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
export default TextAreaGroup;
