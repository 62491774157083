import React from "react";
import { BookingFormConsents } from "./BookingFormConsents";
import { useConsents } from "./use-consents";
import { useBlurOverElement } from "./use-blur-over-element";

export function BookingForm() {
  const consents = useConsents();
  useBlurOverElement(
    "#booking-form-iframe-sibling",
    "#booking-form-iframe",
    consents.consentData !== "true" ||
      consents.consentContract !== "true" ||
      consents.consentUnfairTerms !== "true"
  );

  return <BookingFormConsents consents={consents} />;
}
