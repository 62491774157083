import React from "react";
import PropTypes from "prop-types";

const Response = (props) => {
  return (
    <div>
      <div
        id="response"
        style={{ position: "relative", top: "-160px", left: "0" }}
      ></div>

      {props.showMsg ? (
        <div
          style={
            props.title == "Errore"
              ? {
                  padding: "8px 8px 22px 24px",
                  backgroundColor: "#B33B00",
                  color: "white",
                }
              : {
                  padding: "8px 8px 22px 24px",
                  backgroundColor: "#d0d7e1",
                  color: "#22252a",
                }
          }
        >
          <div
            onClick={() => props.onClose(false)}
            style={{
              textAlign: "right",
              position: "relative",
              marginRight: "5px",
              marginTop: "5px",
            }}
          >
            <svg
              style={
                props.title == "Errore"
                  ? { fill: "white", cursor: "pointer" }
                  : { fill: "#22252a", cursor: "pointer" }
              }
              id="icon-close"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path d="M3.921 23.65L12 15.57l8.079 8.08c.467.467 1.225.467 1.693 0l1.878-1.878c.467-.467.467-1.225 0-1.693L15.57 12l8.079-8.079c.467-.467.467-1.225 0-1.693L21.772.35c-.468-.467-1.226-.467-1.694 0L12 8.43 3.92.351c-.467-.467-1.225-.467-1.693 0L.35 2.228c-.467.467-.467 1.225 0 1.693l8.08 8.08-8.08 8.078c-.467.468-.467 1.226 0 1.694l1.878 1.877c.467.468 1.225.468 1.693 0z"></path>
            </svg>
          </div>

          <div
            style={
              props.title == "Errore"
                ? {
                    fontFamily: "Montserrat",
                    fontSize: "19px",
                    fontWeight: "bold",
                    lineHeight: "1.26",
                    marginBottom: "16px",
                    letterSpacing: "normal",
                    color: "white",
                  }
                : {
                    fontFamily: "Montserrat",
                    fontSize: "19px",
                    fontWeight: "bold",
                    lineHeight: "1.26",
                    marginBottom: "16px",
                    letterSpacing: "normal",
                    color: "#22252a",
                  }
            }
          >
            {props.title}
          </div>
          <div
            style={
              props.title == "Errore"
                ? {
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "white",
                  }
                : {
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#22252a",
                  }
            }
          >
            {props.description}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

Response.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showMsg: PropTypes.bool,
  onClose: PropTypes.func,
};
export default Response;
