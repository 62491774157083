import * as React from "react";
import * as ReactDOM from "react-dom";

export const createMountMethod = (Component, elementId, props = {}) => () => {
  const root = document.getElementById(elementId);
  if (root) {
    ReactDOM.render(
      <Component
        to={root.getAttribute("to")}
        googleAction={root.getAttribute("googleAction")}
        section={root.getAttribute("section")}
        {...props}
      />,
      root
    );
  }
};
