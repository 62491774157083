import React, { useCallback } from "react";
import PropTypes from "prop-types";
import css from "./SingleConsent.css";
import { __ } from "../../model/mainhelper";

export function SingleConsent({
  title,
  externalLinkTitle,
  externalLinkUrl,
  id,
  value,
  setValue,
}) {
  const onConsentChange = useCallback(
    (ev) => {
      setValue(ev.target.checked ? "true" : "false");
    },
    [setValue]
  );
  // const onDoNotConsentChange = useCallback(
  //   (ev) => {
  //     setValue(ev.target.value ? "false" : "true");
  //   },
  //   [setValue]
  // );
  return (
    <div className={css.singleConsent}>
      <div className={css.header}>
        <h4>{title}</h4>
        <p>
          <a href={externalLinkUrl}>{externalLinkTitle}</a>
        </p>
      </div>
      <div className={css.inputs}>
        <input
          type="checkbox"
          checked={value === "true"}
          name={id}
          id={`booking-form-${id}-consent`}
          onChange={onConsentChange}
        />
        <label htmlFor={`booking-form-${id}-consent`}>
          {__("bookingFormReadAndGiveConsent")}
        </label>
        {/* <label id={`booking-form-${id}-do-not-consent`}>
          <input
            type="radio"
            name={id}
            id={`booking-form-${id}-do-not-consent`}
            checked={value !== "true"}
            onChange={onDoNotConsentChange}
          />
          {__("bookingFormNoConsent")}
        </label> */}
      </div>
    </div>
  );
}

SingleConsent.propTypes = {
  title: PropTypes.string.isRequired,
  externalLinkTitle: PropTypes.string.isRequired,
  externalLinkUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
