import React from "react";
import PropTypes from "prop-types";
import { SingleConsent } from "./SingleConsent";
import { __ } from "../../model/mainhelper";
import styles from "./BookingFormConsents.css";

export function BookingFormConsents({ consents }) {
  const conf = window.MarinaDiLoanoConfig;
  return (
    <div className={styles.bookingFormConsents}>
      <SingleConsent
        externalLinkTitle={__("bookingFormReadDocument")}
        externalLinkUrl={conf.bookingFormDataConsentDocumentUrl}
        title={__("bookingFormDataConsentTitle")}
        id="informativa-trattamento-dati"
        value={consents.consentData}
        setValue={consents.setConsentData}
      />
      <SingleConsent
        externalLinkTitle={__("bookingFormReadDocument")}
        externalLinkUrl={conf.bookingFormContractConsentDocumentUrl}
        title={__("bookingFormContractConsentTitle")}
        id="condizioni-generali-contratto"
        value={consents.consentContract}
        setValue={consents.setConsentContract}
      />
      <SingleConsent
        externalLinkTitle={__("bookingFormReadDocument")}
        externalLinkUrl={conf.bookingFormUnfairTermsConsentDocumentUrl}
        title={__("bookingFormUnfairTermsConsentTitle")}
        id="clausole-vessatorie"
        value={consents.consentUnfairTerms}
        setValue={consents.setConsentUnfairTerms}
      />
    </div>
  );
}

BookingFormConsents.propTypes = {
  consents: PropTypes.shape({
    consentData: PropTypes.string.isRequired,
    setConsentData: PropTypes.func.isRequired,
    consentContract: PropTypes.string.isRequired,
    setConsentContract: PropTypes.func.isRequired,
    consentUnfairTerms: PropTypes.string.isRequired,
    setConsentUnfairTerms: PropTypes.func.isRequired,
  }),
};
